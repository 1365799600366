import { ApiStore, Widget, rotationModes } from '@hart-estate/widget';
import '../assets/sass/index.sass';
import logo from '../assets/img/logo.png';

const WIDGET_API_URL = 'https://backend.estate.hart-digital.com';

const createWidget = async (logoUrl) => {
  const widgetApiHandler = new ApiStore(WIDGET_API_URL)

  const searchParams = (new URL(document.location)).searchParams;
  const planId = searchParams.get("id");

  const planData = await widgetApiHandler.loadWidgetData(planId);
  const options = {
    ...planData.parsed,
    API_URL: WIDGET_API_URL,
    rotationMode: rotationModes.DEFAULT,
    logo: logo,
    tabs: ['panorama', 'rotation'],
    locale: 'talan_1',
    logoUrl,
  }

  new Widget('#widget', options);
}

createWidget('https://xn--80aacf4bwnk3a.xn--80aa6ajv.xn--p1ai/apartments/flats?area_range={%22min%22:%2232.4%22,%22max%22:%2293.8%22}&complex=2322376&cost_filter=sale,+booked&cost_filter=presale&cost_range={%22min%22:%229126800%22,%22max%22:%2217650400%22}&floor_range={%22min%22:%221%22,%22max%22:%2224%22}&group_layout=false&hypotec_range={%22min%22:%2236420%22,%22max%22:%2270434%22}&not_first=false&not_last=false&range_type=price&queue=1');

